<template>
<div class="wrap">
  <el-form :model="form" label-width="151px">

    <el-form-item>
      <span slot="label">SiO<sub>2</sub>：</span>
      <el-input-number v-model="form.SiO2_begin" :controls="false"></el-input-number>
      <span class="conection-line"> - </span>
      <el-input-number v-model="form.SiO2_end" :controls="false"></el-input-number>
    </el-form-item>

    <el-form-item>
      <span slot="label">TiO<sub>2</sub>：</span>
      <el-input-number v-model="form.TiO2_begin" :controls="false"></el-input-number>
      <span class="conection-line"> - </span>
      <el-input-number v-model="form.TiO2_end" :controls="false"></el-input-number>
    </el-form-item>

    <el-form-item>
      <span slot="label">Al<sub>2</sub>O<sub>3</sub>：</span>
      <el-input-number v-model="form.Al2O3_begin" :controls="false"></el-input-number>
      <span class="conection-line"> - </span>
      <el-input-number v-model="form.Al2O3_end" :controls="false"></el-input-number>
    </el-form-item>

    <el-form-item>
      <span slot="label">Fe<sub>2</sub>O<sub>3</sub>：</span>
      <el-input-number v-model="form.Fe2O3_begin" :controls="false"></el-input-number>
      <span class="conection-line"> - </span>
      <el-input-number v-model="form.Fe2O3_end" :controls="false"></el-input-number>
    </el-form-item>

    <el-form-item>
      <span slot="label">Fe<sub>2</sub>O<sub>3</sub>T：</span>
      <el-input-number v-model="form.Fe2O3T_begin" :controls="false"></el-input-number>
      <span class="conection-line"> - </span>
      <el-input-number v-model="form.Fe2O3T_end" :controls="false"></el-input-number>
    </el-form-item>

    <el-form-item>
      <span slot="label">FeO：</span>
      <el-input-number v-model="form.FeO_begin" :controls="false"></el-input-number>
      <span class="conection-line"> - </span>
      <el-input-number v-model="form.FeO_end" :controls="false"></el-input-number>
    </el-form-item>

    <el-form-item>
      <span slot="label">FeOT：</span>
      <el-input-number v-model="form.FeOT_begin" :controls="false"></el-input-number>
      <span class="conection-line"> - </span>
      <el-input-number v-model="form.FeOT_end" :controls="false"></el-input-number>
    </el-form-item>

    <el-form-item>
      <span slot="label">MnO：</span>
      <el-input-number v-model="form.MnO_begin" :controls="false"></el-input-number>
      <span class="conection-line"> - </span>
      <el-input-number v-model="form.MnO_end" :controls="false"></el-input-number>
    </el-form-item>

    <el-form-item>
      <span slot="label">MgO：</span>
      <el-input-number v-model="form.MgO_begin" :controls="false"></el-input-number>
      <span class="conection-line"> - </span>
      <el-input-number v-model="form.MgO_end" :controls="false"></el-input-number>
    </el-form-item>

    <el-form-item>
      <span slot="label">CaO：</span>
      <el-input-number v-model="form.CaO_begin" :controls="false"></el-input-number>
      <span class="conection-line"> - </span>
      <el-input-number v-model="form.CaO_end" :controls="false"></el-input-number>
    </el-form-item>

    <el-form-item>
      <span slot="label">Na<sub>2</sub>O：</span>
      <el-input-number v-model="form.Na2O_begin" :controls="false"></el-input-number>
      <span class="conection-line"> - </span>
      <el-input-number v-model="form.Na2O_end" :controls="false"></el-input-number>
    </el-form-item>

    <el-form-item>
      <span slot="label">K<sub>2</sub>O：</span>
      <el-input-number v-model="form.K2O_begin" :controls="false"></el-input-number>
      <span class="conection-line"> - </span>
      <el-input-number v-model="form.K2O_end" :controls="false"></el-input-number>
    </el-form-item>

    <el-form-item>
      <span slot="label">P<sub>2</sub>O<sub>5</sub>：</span>
      <el-input-number v-model="form.P2O5_begin" :controls="false"></el-input-number>
      <span class="conection-line"> - </span>
      <el-input-number v-model="form.P2O5_end" :controls="false"></el-input-number>
    </el-form-item>

    <el-form-item>
      <span slot="label">LOI(loss on ignition)：</span>
      <el-input-number v-model="form.LOI_loss_on_ignition_begin" :controls="false"></el-input-number>
      <span class="conection-line"> - </span>
      <el-input-number v-model="form.LOI_loss_on_ignition_end" :controls="false"></el-input-number>
    </el-form-item>

    <el-form-item>
      <el-button type="primary" icon="el-icon-search" @click="onSearch">{{$t('action.search')}}</el-button>
      <!-- <el-button type="danger" icon="el-icon-s-promotion" round>重置</el-button> -->
    </el-form-item>

  </el-form>
</div>
</template>

<script>
import dPrincipalElementApi from '@/api/dPrincipalElement'
export default {
  data() {
    return {
      form: {
        SiO2_begin: 0,
        SiO2_end: 0,
        TiO2_begin: 0,
        TiO2_end: 0,
        Al2O3_begin: 0,
        Al2O3_end: 0,
        Fe2O3_begin: 0,
        Fe2O3_end: 0,
        Fe2O3T_begin: 0,
        Fe2O3T_end: 0,
        FeO_begin: 0,
        FeO_end: 0,
        FeOT_begin: 0,
        FeOT_end: 0,
        MnO_begin: 0,
        MnO_end: 0,
        MgO_begin: 0,
        MgO_end: 0,
        CaO_begin: 0,
        CaO_end: 0,
        Na2O_begin: 0,
        Na2O_end: 0,
        K2O_begin: 0,
        K2O_end: 0,
        P2O5_begin: 0,
        P2O5_end: 0,
        LOI_loss_on_ignition_begin: 0,
        LOI_loss_on_ignition_end: 0
      }
    }
  },
  methods: {
    assembleWheres() {
      let form = this.form
      let wheres = []
      if (form.SiO2_begin || form.SiO2_end) {
        wheres.push(
          {name: 'SiO2', value: form.SiO2_begin, displayType: 'thanorequal'},
          {name: 'SiO2', value: form.SiO2_end, displayType: 'lessorequal'}
        )
      }
      if (form.TiO2_begin || form.TiO2_end) {
        wheres.push(
          {name: 'TiO2', value: form.TiO2_begin, displayType: 'thanorequal'},
          {name: 'TiO2', value: form.TiO2_end, displayType: 'lessorequal'}
        )
      }
      if (form.Al2O3_begin || form.Al2O3_end) {
        wheres.push(
          {name: 'Al2O3', value: form.Al2O3_begin, displayType: 'thanorequal'},
          {name: 'Al2O3', value: form.Al2O3_end, displayType: 'lessorequal'}
        )
      }
      if (form.Fe2O3_begin || form.Fe2O3_end) {
        wheres.push(
          {name: 'Fe2O3', value: form.Fe2O3_begin, displayType: 'thanorequal'},
          {name: 'Fe2O3', value: form.Fe2O3_end, displayType: 'lessorequal'}
        )
      }
      if (form.Fe2O3T_begin || form.Fe2O3T_end) {
        wheres.push(
          {name: 'Fe2O3T', value: form.Fe2O3T_begin, displayType: 'thanorequal'},
          {name: 'Fe2O3T', value: form.Fe2O3T_end, displayType: 'lessorequal'}
        )
      }
      if (form.FeO_begin || form.FeO_end) {
        wheres.push(
          {name: 'FeO', value: form.FeO_begin, displayType: 'thanorequal'},
          {name: 'FeO', value: form.FeO_end, displayType: 'lessorequal'}
        )
      }
      if (form.FeOT_begin || form.FeOT_end) {
        wheres.push(
          {name: 'FeOT', value: form.FeOT_begin, displayType: 'thanorequal'},
          {name: 'FeOT', value: form.FeOT_end, displayType: 'lessorequal'}
        )
      }
      if (form.MnO_begin || form.MnO_end) {
        wheres.push(
          {name: 'MnO', value: form.MnO_begin, displayType: 'thanorequal'},
          {name: 'MnO', value: form.MnO_end, displayType: 'lessorequal'}
        )
      }
      if (form.MgO_begin || form.MgO_end) {
        wheres.push(
          {name: 'MgO', value: form.MgO_begin, displayType: 'thanorequal'},
          {name: 'MgO', value: form.MgO_end, displayType: 'lessorequal'}
        )
      }
      if (form.CaO_begin || form.CaO_end) {
        wheres.push(
          {name: 'CaO', value: form.CaO_begin, displayType: 'thanorequal'},
          {name: 'CaO', value: form.CaO_end, displayType: 'lessorequal'}
        )
      }
      if (form.Na2O_begin || form.Na2O_end) {
        wheres.push(
          {name: 'Na2O', value: form.Na2O_begin, displayType: 'thanorequal'},
          {name: 'Na2O', value: form.Na2O_end, displayType: 'lessorequal'}
        )
      }
      if (form.K2O_begin || form.K2O_end) {
        wheres.push(
          {name: 'K2O', value: form.K2O_begin, displayType: 'thanorequal'},
          {name: 'K2O', value: form.K2O_end, displayType: 'lessorequal'}
        )
      }
      if (form.P2O5_begin || form.P2O5_end) {
        wheres.push(
          {name: 'P2O5', value: form.P2O5_begin, displayType: 'thanorequal'},
          {name: 'P2O5', value: form.P2O5_end, displayType: 'lessorequal'}
        )
      }
      if (form.LOI_loss_on_ignition_begin || form.LOI_loss_on_ignition_end) {
        wheres.push(
          {name: 'LOI_loss_on_ignition', value: form.LOI_loss_on_ignition_begin, displayType: 'thanorequal'},
          {name: 'LOI_loss_on_ignition', value: form.LOI_loss_on_ignition_end, displayType: 'lessorequal'}
        )
      }

      return wheres;
    },
    assembleWheres2() {
      let form = this.form
      let wheres = []
      form.SiO2_begin && wheres.push(`SiO2 >= ${form.SiO2_begin}`)
      form.SiO2_end && wheres.push(`SiO2 <= ${form.SiO2_end}`)

      form.TiO2_begin && wheres.push(`TiO2 >= ${form.TiO2_begin}`)
      form.TiO2_end && wheres.push(`TiO2 <= ${form.TiO2_end}`)

      form.Al2O3_begin && wheres.push(`Al2O3 >= ${form.Al2O3_begin}`)
      form.Al2O3_end && wheres.push(`Al2O3 <= ${form.Al2O3_end}`)

      form.Fe2O3_begin && wheres.push(`Fe2O3 >= ${form.Fe2O3_begin}`)
      form.Fe2O3_end && wheres.push(`Fe2O3 <= ${form.Fe2O3_end}`)

      form.Fe2O3T_begin && wheres.push(`Fe2O3T >= ${form.Fe2O3T_begin}`)
      form.Fe2O3T_end && wheres.push(`Fe2O3T <= ${form.Fe2O3T_end}`)

      form.FeO_begin && wheres.push(`FeO >= ${form.FeO_begin}`)
      form.FeO_end && wheres.push(`FeO <= ${form.FeO_end}`)

      form.FeOT_begin && wheres.push(`FeOT >= ${form.FeOT_begin}`)
      form.FeOT_end && wheres.push(`FeOT <= ${form.FeOT_end}`)
      
      form.MnO_begin && wheres.push(`MnO >= ${form.MnO_begin}`)
      form.MnO_end && wheres.push(`MnO <= ${form.MnO_end}`)
      
      form.MgO_begin && wheres.push(`MgO >= ${form.MgO_begin}`)
      form.MgO_end && wheres.push(`MgO <= ${form.MgO_end}`)
      
      form.CaO_begin && wheres.push(`CaO >= ${form.CaO_begin}`)
      form.CaO_end && wheres.push(`CaO <= ${form.CaO_end}`)
      
      form.Na2O_begin && wheres.push(`Na2O >= ${form.Na2O_begin}`)
      form.Na2O_end && wheres.push(`Na2O <= ${form.Na2O_end}`)

      form.K2O_begin && wheres.push(`K2O >= ${form.K2O_begin}`)
      form.K2O_end && wheres.push(`K2O <= ${form.K2O_end}`)

      form.P2O5_begin && wheres.push(`P2O5 >= ${form.P2O5_begin}`)
      form.P2O5_end && wheres.push(`P2O5 <= ${form.P2O5_end}`)
      
      form.LOI_loss_on_ignition_begin && wheres.push(`LOI_loss_on_ignition >= ${form.LOI_loss_on_ignition_begin}`)
      form.LOI_loss_on_ignition_end && wheres.push(`LOI_loss_on_ignition <= ${form.LOI_loss_on_ignition_end}`)
      return wheres.join(' and ');
    },
    // 获取主量元素信息
    getPrincipalElementData() {
      dPrincipalElementApi.getPageData({
        page: 1,
        rows: 999,
        wheres: JSON.stringify(this.assembleWheres())
      }).then(res => {
        let sumpleIds = res.rows.map(item => item.Sample_ID)
        let wheres = []
        wheres.push({name: 'Sample_ID', value: sumpleIds.join(','), displayType: 'selectList'})
        this.$router.push(`/search/result?wheres=${JSON.stringify(wheres)}`)
      }).catch(err => {
        // this.talbe.loading = false
      })
    },
    onSearch() {
      let wheres = this.assembleWheres2()
      this.$router.push(`/search/result?values=${wheres}`)
    }
  },
  mounted() {
    // this.getData()
  }
}
</script>

<style lang="less" scoped>
  .wrap {
    padding: 20px;
    background-color: #fff;
    border: 1px solid #eee;
  }
  /deep/ .el-input-number{width: 300px;}
  /deep/ .el-input__inner{border-radius: 2px; text-align: left;}
  /deep/ .el-input__inner:hover, /deep/ .el-input.is-active .el-input__inner, /deep/ .el-input__inner:focus{border-color: #DCDFE6;}
  .conection-line{padding: 0 10px; font-weight: lighter; text-align: center;}
</style>
